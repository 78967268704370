<template>
  <footer class="footer">
    <div class="footer-content">
      Todos los Derechos Reservados © Betapuesta.mx, 2024
    </div>
    </footer>
</template>

<script>
  export default {
    name: "FooterComponent",
  };
</script>
