<template>
  <header class="header ">
    <div class="nav-container max-container">
      <div class="first-part">
        <div class="">
          <a href="/">
            <img class="header-content__img" src="@/assets/images/logo.png" alt="betapuesta" />
          </a>
        </div>
        <div class="header-content__cta">
          <div class="header-buttons">
            <!-- <form class="login__form form__mobile" method="post" :action="`//mobile.${backendUrl}/DefaultLogin.aspx`">
              <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
              <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`" />
              <input class="login__form--inputs" type="text" placeholder="USERNAME" name="account" id="account">
              <input class="login__form--inputs" type="password" placeholder="PASSWORD" name="password" id="password">
              <div class="header-buttons">
                <button class="btn-login secondary-button secondary-button__classic" type="submit">Login</button>
                <a href="#" class="btn-login secondary-button secondary-button__betslip" @click="openModal">Betslip</a>
              </div>
            </form> -->
            <!-- Desktop form -->
            <!-- <form class="login__form form__desktop" method="post" :action="`//wager.${backendUrl}/DefaultLogin.aspx`">
              <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
              <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`" />
              <input class="login__form--inputs" type="text" placeholder="USERNAME" name="account" id="account">
              <input class="login__form--inputs" type="password" name="password" placeholder="PASSWORD" id="password">
              <div class="header-buttons">
                <button class="btn-login secondary-button secondary-button__classic" type="submit">Login</button>
                <a href="#" class="btn-login secondary-button secondary-button__betslip" @click="openModal">Betslip</a>
              </div>
            </form> -->
            <a href="#" class="btn-login secondary-button secondary-button__classic" @click="openModal(2)">Ingresar</a>
            <a href="#" class="btn-login secondary-button secondary-button__betslip" @click="openModal(1)">Betslip</a>
          </div>
        </div>
      </div>
      <div class="second-part">
        <nav class="header-content__nav2">
          <div class="header-nav__container">
            <ul class="header-nav__menu">
              <div class="header-content__info">
                <ul class="header-content__info--list">
                  <li class="header-content__info--list-item">
                    <router-link to="house-rules">REGLAMENTO</router-link>
                  </li>
                  <li class="header-content__info--list-item">
                    <router-link to="sports-rules">REGLAS DEPORTES</router-link>
                  </li>
                  <li class="header-content__info--list-item">
                    <router-link to="sportsbook">LÍNEA DE APUESTAS</router-link>
                  </li>
                  <li class="header-content__info--list-item">
                    <a href="http://agents2.betapuesta.mx/">AGENTES</a>
                  </li>
                  <li class="header-content__info--list-item">
                    <a href="https://adm.betapuesta.mx/">AGENTES 2.0</a>
                  </li>
                  <a href="#" class="btn-login secondary-button secondary-button__classic" @click="openModal(2)">Ingresar</a>
                  <a href="#" class="btn-login secondary-button secondary-button__betslip" @click="openModal(1)">Betslip</a>
                </ul>
              </div>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <ModalComponent ref="modal" />
  </header>
</template>

<script>
import ModalComponent from "@/components/modal.vue";

export default {
  name: "HeaderComponent",
  components: {
    ModalComponent,
  },
  methods: {
    openModal(type) {
      this.$refs.modal.openModal(type);
    },
  },
  setup:() => {
      const backendUrl = "betapuesta.mx";
      const DGS_SITEID = 544;
      
      return {
        backendUrl,
        DGS_SITEID
      }
    },
};
</script>
