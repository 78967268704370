<template>
  <div class="hamburger-menu">
    <button class="hamburger-menu__button" @click="isActive = !isActive" :class="{ 'is-active': isActive }">
      <span class="material-symbols-rounded header-content__hamburger">menu</span>
    </button>
    <ul class="hamburger-menu__list" :class="{ 'is-active': isActive }">
      <img class="hamburger-menu__logo" src="@/assets/images/logo.png" alt="logo">
      <button @click="isActive = !isActive" class="hamburger-menu__close-button" aria-label="Cerrar menú"><span
          class="material-symbols-rounded">cancel</span></button>
      <li class="hamburger-menu__item" @click="isActive = !isActive" v-for="(route, index) in routes" :key="index">

        <router-link v-if="route.name != 'AGENTES' && route.name != 'AGENTES 2.0'" :to="route.route">{{
          route.name }}</router-link>
        <a :href=route.route v-if="route.name == 'AGENTES' || route.name == 'AGENTES 2.0'">{{
          route.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MenuComponent",
  data() {
    return {
      isActive: false,
      routes: [
        {
          route: 'house-rules',
          name:'REGLAMENTO'
        },
        {
          route: 'sports-rules',
          name:'REGLAS DEPORTES'
        },
        {
          route: 'sportsbook',
          name:'LÍNEA DE APUESTAS'
        },
        {
          route: 'https://magents.betapuesta.mx',
          name:'AGENTES'
        },
        {
          route: 'https://adm.betapuesta.mx/login',
          name:  'AGENTES 2.0'
        }
      ],
      ItemImg: [
        {
          image: require("@/assets/images/icons/sports.svg"),
          alt: "Lorem ipsum",
        },
        {
          image: require("@/assets/images/icons/live.svg"),
          alt: "Lorem ipsum",
        },
        {
          image: require("@/assets/images/icons/casino.svg"),
          alt: "Lorem ipsum",
        },
        {
          image: require("@/assets/images/icons/horses.svg"),
          alt: "Lorem ipsum",
        }
      ]
    };
  },
};
</script>
