<template>
  <div class="modal-backdrop" v-if="showModal">
    <div class="modal-content__heading">
      <h3>Ingreso de Usuario</h3>
      <button class="close-button" @click="closeModal">
        <span class="material-symbols-rounded">close</span>
      </button>
    </div>
    <div class="modal-content" v-if="type == 1">
      <!-- Contenido de tu modal aquí -->
      <form class="login__form" name="LoginForm" action="javascript:void(0)" onsubmit="BackEndLogin(this); return false">
        <div class="login__form--item">
          <label class="login__form--label" for="username">Usuario</label>
          <input class="login__form--input" name="username" id="username" type="text" required placeholder=""/>
        </div>

        <div class="login__form--item">
          <label class="login__form--label" for="password">Contraseña</label>
          <input class="login__form--input" type="password" name="password" id="password" required placeholder=""/>
        </div>
        
        <div class="text-center mt-2"> 
          <span name="msj_loading" id="id-login-loading"></span>
          <small class="text-danger" name="msj_error_lg"></small>
        </div>

        
        <div class="login__btns-login">
          <input type="submit" class="login-button" name="send" id="send" value="Ingresar">
          <input type="hidden" name="BackEndUrl" :value="`https://apuestas.${BackEndUrl}`">
        </div>
        <!-- <div class="login__link">
          <router-link to="forgot-pass" @click="closeModal" >Forgot password ?</router-link>
        </div> -->
      </form>
    </div>
    <div class="modal-content" v-if="type == 2">
      
      <form class="login__form form__mobile" method="post" :action="`//mobile.${BackEndUrl}/DefaultLogin.aspx`">

          <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
          <input type="hidden" name="errorURL" :value="`//www.${BackEndUrl}/?login-error`" />
          <div class="login__form--item">
            <label class="login__form--label" for="username">Usuario</label>
            <input class="login__form--input" type="text" placeholder="" name="account" id="account">
          </div>
          <div class="login__form--item">
            <label class="login__form--label" for="password">Contraseña</label>
            <input class="login__form--input" type="password" placeholder="" name="password" id="password">
          </div>
          <div class="login__btns-login">
            <button class="login-button" type="submit">Ingresar</button>
          </div>
      </form>
      <!-- Desktop form -->
       
      <form class="login__form form__desktop" method="post" :action="`//wager.${BackEndUrl}/DefaultLogin.aspx`">
          <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
          <input type="hidden" name="errorURL" :value="`//www.${BackEndUrl}/?login-error`" />
          <div class="login__form--item">
            <label class="login__form--label" for="username">Usuario</label>
            <input class="login__form--input" type="text" placeholder="" name="account" id="account">
          </div>
          <div class="login__form--item">
            <label class="login__form--label" for="password">Contraseña</label>
            <input class="login__form--input" type="password" name="password" placeholder="" id="password">
          </div>
          <div class="login__btns-login">
            <button class="login-button" type="submit">Ingresar</button>
          </div>
      </form>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ModalComponent',
    setup:() => {
      const BackEndUrl = "betapuesta.mx";
      const DGS_SITEID = 544;
      return {
        BackEndUrl,
        DGS_SITEID,
      }
    },
    data() {
      return {
        showModal: false
      }
    },
    methods: {
      openModal(type) {
        this.type = type;
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
      }
    }
  }
</script>